.public-page {
    background: linear-gradient(180deg, #27ae60 0%, #2d9cdb 100%);
}

.container {
    margin: 0 auto;
    padding: 30px 5vw;
    width: 60vw;
    background-color: #fff;
}

.title,
.subtitle,
.description {
    text-align: center;
}

// .title {
// }

// .subtitle {
// }

// .description {
// }

@media (max-width: 640px) {
    .container {
        width: 80vw;
    }
}
