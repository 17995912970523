.forgot-page {
    width: 600px;
    margin: 0 auto;
    padding-top: 60px;

    &__title {
        margin-bottom: 50px;
    }

    &__form {
        margin-bottom: 40px;
        & .input-wrapper {
            height: 135px;
        }
    }

    &__error-field {
        margin-bottom: 10px;
    }
}
