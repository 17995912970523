.poll-form {
    margin-top: 40px;
    &__label-wraper {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        .field-label {
            margin-bottom: 10px;
            font-size: 18px;
            font-weight: bold;
        }
        .field-name {
            font-size: 20px;
        }
    }
    &__submit-btn {
        margin-top: 48px;
    }
}
