.first-page {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    &__sub-title {
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        text-transform: uppercase;
        margin-top: 50px;
        margin-bottom: 150px;
        width: 370px;
        color: map-get($map: $colors, $key: 'dark-text');
    }

    &__title {
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 56px;
        align-items: center;
        text-align: center;
        margin-bottom: 75px;
        color: #4f4f4f;
    }

    &__btn-wrapper {
        width: 700px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;
    }
    &__btn {
        width: 300px;
        height: 60px;
        &--login {
            color: #fff;
        }
    }

    &__license {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        align-items: center;
        text-align: center;
        position: absolute;
        bottom: 100px;
        color: #000000;
    }
}
