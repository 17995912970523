.confirm-singup-page {
    width: 600px;
    margin: 0 auto;
    padding: 60px 0;
    display: flex;
    flex-direction: column;

    &__title {
        margin-bottom: 49px;
    }

    &__info {
        margin-bottom: 25px;
    }

    &__emailbtn {
        margin-bottom: 40px;
    }

    &__error-field {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 10px;

        color: #eb5757;
    }

    &__code-wrapper {
        height: 50px;
        margin-bottom: 40px;
        display: flex;
        justify-content: center;
    }

    &__confirm {
        margin-bottom: 40px;
    }
}
