.singup-page {
    width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 100px 0 100px;

    &__form {
        margin-bottom: 20px;
        .login {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
        }

        .add-use {
            padding: 5px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-content: center;
            color: map-get($map: $colors, $key: 'dark-text');

            .reset-link {
                color: map-get($map: $colors, $key: 'dark-text');
            }
        }
        .error-field {
            margin-bottom: 10px;
        }
    }
    &__login-text {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;

        text-align: center;

        color: map-get($map: $colors, $key: 'dark-text');
    }

    &__login-link {
        font-weight: bold !important;
        font-size: 18px !important;
    }
}
