.decoration {
    width: 677px;
    height: 2px;
    background: #333333;
    left: 50%;
    transform: translateX(-50%);
    &--bottom {
        position: absolute;
        bottom: 73px;
    }

    &--top {
        position: absolute;
        top: 83px;
    }
    &__dot-wrapper {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        flex-direction: row;
        right: 0;
    }

    &__dot {
        &:not(:last-child) {
            margin-right: 14px;
        }
        width: 26px;
        height: 26px;
        border-radius: 50%;

        &--blue {
            background: map-get($map: $colors, $key: 'main');
        }
        &--green {
            background: map-get($map: $colors, $key: 'secondary');
        }
        &--grey {
            background: map-get($map: $colors, $key: 'gray');
        }
    }
}
