.auth-sidebar {
    position: relative;
    min-width: 375px;
    width: $authsidebarWidth;
    height: 100vh;
    background: linear-gradient(180deg, #27ae60 0%, #2d9cdb 100%);

    &__logo {
        background-image: url('../../../../assets/icons/logo.png');
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 353px 365px;
    }
}
