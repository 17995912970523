.with-main-sidebar-page {
    display: flex;
    height: 100vh;
    overflow: clip;
    &__content {
        position: relative;
        width: calc(100vw - #{$mainSidebarWidth});
        padding: 40px 10px;
        overflow-y: auto;
        height: calc(100% - 80px);
    }
}
