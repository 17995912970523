.reset-password {
    width: 600px;
    margin: 0 auto;
    padding-top: 40px;
    display: flex;
    flex-direction: column;

    &__title {
        margin-bottom: 50px;
    }

    &__info {
        margin-bottom: 25px;
    }
    &__sent-again-btn {
        margin-bottom: 40px;
    }

    &__code-wrapper {
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
    }

    &__form {
        margin-bottom: 40px;
        .input-wrapper {
            height: 250px;
        }
    }
    &__go-back {
        margin-bottom: 150px;
    }
}
