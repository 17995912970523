.main-sidebar {
    position: relative;
    min-width: 250px;
    width: $mainSidebarWidth;
    height: 100vh;
    background: linear-gradient(180deg, #27ae60 0%, #2d9cdb 100%);

    padding: 70px 0 70px 50px;

    &__logo {
        width: 100%;
        //height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 353px 365px;
    }

    &__container {
        height: calc(100vh - 224px);
        margin-top: 70px;
        width: calc(100%);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__items {
        display: flex;
        flex-direction: column;
    }

    &__item {
        width: 100%;
        height: 78px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 50px 0px 0px 50px;

        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        border: none;
        text-decoration: none;

        &-active {
            background: #ffffff;
        }
    }

    &__exit {
        width: 100%;
        height: 78px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 50px 0px 0px 50px;
        cursor: pointer;
        margin-bottom: 25px;
        border: none;
    }

    &-active {
        background: #ffffff;
    }
}
