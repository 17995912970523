@import './mixins';
@import './variables';

// components
@import '../components/Button/Button.scss';
@import '../components/ConfirmationDialog/ConfirationDialog.scss';
@import '../components/CreateButton/CreateButton.scss';
@import '../components/ProfileForm/ProfileForm.scss';
// @import '../components/AuthSidebar/authSidebar.scss';
@import '../components/Decoration/decoration.scss';
@import '../components/LinkButton/linkButton.scss';
@import '../components/WithAuthSidebar/withAuthSidebar.scss';
@import '../components/WithAuthSidebar/components/AuthSidebar/authSidebar.scss';
@import '../components/WithMainSidebar/components/MainSidebar/MainSidebar.scss';
@import '../components/WithMainSidebar/WithMainSidebar.scss';
@import '../components/SuccessCreated/successCreated.scss';
// pages
@import '../pages/FirstPage/firstPage.scss';
@import '../pages/Login/login.scss';
@import '../pages/SignUp/signUp.scss';
@import '../pages/ConfimSignUp/confimSignUp.scss';
@import '../pages/ForgotPassword/forgotPassword.scss';
@import '../pages/ResetPassword/resetPassword.scss';
@import '../pages/CreateSurveyPage/createSurveyPage.scss';
@import '../pages/MySuryves/mySurvey.scss';

// font
@font-face {
    font-family: 'Roboto', sans-serif;
    src: url('../assets/fonts/Roboto-Regular.ttf') format('ttf'), url('../assets/fonts/Roboto-Medium.ttf') format('ttf'),
        url('../assets/fonts/Roboto-Bold.ttf') format('ttf');
}

* {
    padding: 0;
    margin: 0;
    font-family: 'Roboto', sans-serif;
}

.error-field {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;
    text-align: center;

    color: #eb5757;
}
