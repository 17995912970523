.chart-page {
    padding: 40px;
    margin: 0 auto;
    width: 700px;

    &__title {
        font-size: 30px;
        text-transform: uppercase;
        margin-bottom: 40px;
        text-align: center;
    }

    &__label-wraper {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        .field-label {
            margin-bottom: 10px;
            font-size: 18px;
            font-weight: bold;
        }
        .field-name {
            font-size: 20px;
        }
    }

    &__answer-wrapper {
        margin: 0 auto;
        width: 500px;
    }
    &__answer-title {
        font-size: 14px;
        margin-bottom: 35px;
    }
    &__answer-text {
        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }

    &__chart-wrapper {
        height: 550px;
        display: inline-block;

        &--custom {
            height: auto;
        }
    }
}

@media print {
    .chart-page {
        &__first-block {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
        }
    }
}
