.success-created {
    &__input {
        margin-bottom: 30px;
    }
    &__copy-wrapper {
        width: 100%;
        margin-bottom: 200px;
    }
    &__copy-btn {
        margin: 0 auto;
        display: block;
    }
    &__copy-text {
        margin-top: 20px;
    }
}
