.with-auth-sidebar-page {
    display: flex;

    &__scroll-wrapper {
        height: 100vh;
        overflow-y: auto;
        width: calc(100% - #{$authsidebarWidth});
    }

    &__content {
        position: relative;
        padding: 70px 10px 60px;
        min-height: calc(100% - 130px);

        .title {
            text-transform: uppercase;
            font-style: normal;
            font-weight: normal;
            font-size: 36px;
            text-align: left;
            line-height: 42px;
            color: map-get($map: $colors, $key: 'dark-text');
        }

        .info {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 21px;
        }
    }
}
