.my-survey {
    padding: 90px 0;
    width: 90%;
    margin: 0 auto;
    &__header {
        display: flex;
        width: 70%;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 30px;
    }

    &__title {
        margin-right: 40px;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 42px;
        text-transform: uppercase;
        color: #333333;
    }

    &__subtitle {
        margin-bottom: 20px;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        text-transform: uppercase;
        color: #333333;
    }
}
