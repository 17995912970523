.period-report {
    width: 500px;
    margin: 0 auto;
    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
    }

    &__input {
        margin-bottom: 40px;
        width: 200px;
    }
    &__date-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &__smt-btn {
        width: 250px;
    }
}
