:root {
    --main-color: #2d9cdb;
    --secondary-color: #27ae60;
    --main-bg-color: rgba(45, 156, 219, 0.15);
    --secondary-bg-color: #f2f2f2;
    --text-color: #4f4f4f;
}

$colors: (
    'dark-text': #333333,
    'main': #2d9cdb,
    'secondary': #27ae60,
    'gray': #c4c4c4,
);

// // size
$authsidebarWidth: 35%;
$mainSidebarWidth: 10vw;
